import React, { useState, useEffect } from 'react'
import { SearchPageContainer } from './style'
import { Grid, IconButton, Typography } from '../../../../../../base'
import { FontAwesomeIcon, fas } from '../../../../../../fontAwesome'

import { COLOR_GRAY } from '../../../../../../themes'
import SearchFilters from './SearchFilters'
import LoadingContainer from '../../../../LoadingContainer/LoadingContainer'
import SearchFilterItem from './SearchFilterItem'

const SearchPage = ({
    loading,
    handleCloseSearch,
    filterOptions,
    selectedFilter,
    handleFilterChange,
    searchResults,
}) => {
    const [searchResultsJSX, setSearchResultsJSX] = useState()

    useEffect(() => {
        if (searchResults && Object.keys(searchResults).length) {
            let consumingAppSearchFormatting =
                window.APP_HEADER_HANDLE_DISPLAY_SEARCH_RESULTS(
                    searchResults,
                    selectedFilter
                )

            // if typeof string, make into jsx
            if (typeof consumingAppSearchFormatting === 'string') {
                consumingAppSearchFormatting = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: consumingAppSearchFormatting,
                        }}
                    />
                )
                // consumingAppSearchFormatting = React.createElement(consumingAppSearchFormatting);
            }

            setSearchResultsJSX(consumingAppSearchFormatting)
        }
    }, [searchResults, selectedFilter])

    return (
        <SearchPageContainer>
            <Grid container style={{ paddingTop: '32px' }}>
                <Grid item xs={3} sx={{ marginTop: '20px' }}>
                    <SearchFilters
                        filters={filterOptions}
                        selected={selectedFilter}
                        handleSelection={handleFilterChange}
                        FilterComponent={SearchFilterItem}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="column" alignItems="flex-start">
                        <Typography
                            variant="h4"
                            sx={{
                                fontSize: '19px',
                                fontWeight: '600',
                                color: COLOR_GRAY['900'],
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                                paddingLeft: '39px',
                                marginBottom: '12px',
                            }}
                        >
                            Search Results
                        </Typography>
                    </Grid>
                    <LoadingContainer loading={loading}>
                        {searchResultsJSX}
                    </LoadingContainer>
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction="column" alignItems="center">
                        <IconButton onClick={handleCloseSearch}>
                            <FontAwesomeIcon color="#444" icon={fas.faClose} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </SearchPageContainer>
    )
}

export default SearchPage
