import React from 'react'
import FormLabel from './FormLabel'
import FormController from './FormController'
import InputType from './InputType'

const FormInput = (props) => {
    const {
        disabled,
        displayValue,
        displayEmpty,
        disableCloseOnSelect,
        label,
        name,
        options,
        optionLabel,
        optionValue,
        placeholder,
        tooltip,
        tooltipButton,
        type,
        groups,
        limitTags,
        defaultValues,
        row,
        isRequired,
        multiple,
        apiCall,
        onResults,
        onValueChange,
        freeSolo,
        groupBy,
        checked,
        inderteminate,
        onChange,
        value,
        loading,
        loadingText,
        renderOption,
        renderValue,
        paperComponent,
        isOptionEqualToValue,
        icon,
        getOptionLabel,
        minRows,
        maxRows,
        filterOptions,
        size,
        step,
        min,
        max,
        noResultsText,
    } = props

    const shouldDisplayLabel = type !== 'checkbox' && label

    const formController = (
        <FormController name={name} options={options}>
            <InputType
                label={label}
                freeSolo={freeSolo}
                disabled={disabled}
                displayValue={displayValue}
                displayEmpty={displayEmpty}
                disableCloseOnSelect={disableCloseOnSelect}
                optionLabel={optionLabel}
                optionValue={optionValue}
                placeholder={placeholder}
                type={type}
                groups={groups}
                limitTags={limitTags}
                defaultValues={defaultValues}
                row={row}
                isRequired={isRequired}
                multiple={multiple}
                apiCall={apiCall}
                onResults={onResults}
                onValueChange={onValueChange}
                groupBy={groupBy}
                checked={checked}
                inderteminate={inderteminate}
                onChange={onChange}
                value={value}
                loading={loading}
                loadingText={loadingText}
                renderOption={renderOption}
                renderValue={renderValue}
                paperComponent={paperComponent}
                isOptionEqualToValue={isOptionEqualToValue}
                icon={icon}
                getOptionLabel={getOptionLabel}
                tooltip={tooltip}
                minRows={minRows}
                maxRows={maxRows}
                filterOptions={filterOptions}
                size={size}
                step={step}
                min={min}
                max={max}
                noResultsText={noResultsText}
            />
        </FormController>
    )

    return (
        <>
            {shouldDisplayLabel && (
                <FormLabel
                    label={label}
                    tooltip={tooltip}
                    type={type}
                    tooltipButton={tooltipButton}
                >
                    {formController}
                </FormLabel>
            )}
            {!shouldDisplayLabel && formController}
        </>
    )
}

export default FormInput
