import { useEffect, useState } from 'react'
// import mockResponse from '../../../MockDefinitions/SPI-2.0-response.json'

export const useSearch = (url, token) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedFilter, setFilter] = useState(0)
    const handleFilterChange = (event, index) => {
        setFilter(index)
    }
    const [isSearchOpen, setSearchOpen] = useState(false)
    const handleCloseSearch = () => {
        setSearchOpen(false)
        setSearchTerm('')
        setFilter(0)
    }

    const [searchResults, setSearchResults] = useState()

    useEffect(() => {
        if (searchTerm && !isSearchOpen) {
            setSearchOpen(true)
        }
    }, [searchTerm])

    useEffect(() => {
        if (searchTerm) {
            // todo: make filter dynamic and not hardcoded to All
            const handler = setTimeout(async () => {
                const fullUrl = `${url}?term=${searchTerm}&filter=All`
                try {
                    const response = await fetch(fullUrl, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    })

                    if (!response.ok) {
                        throw new Error(`Response status: ${response.status}`)
                    }

                    const json = await response.json()

                    setSearchResults(json)
                    // setSearchResults(mockResponse);
                } catch (error) {
                    console.error(error.message)
                }
            }, 500)

            return () => {
                clearTimeout(handler)
            }
        }
    }, [searchTerm])

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value.target.value)
    }

    return {
        searchTerm,
        onChangeSearchTerm,
        selectedFilter,
        handleFilterChange,
        isSearchOpen,
        setSearchOpen,
        handleCloseSearch,
        searchResults,
    }
}
