import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
    DropdownTextField,
    InputTextField,
    InputStepperField,
} from '../../../base/TextField'
import Upload from '../../../stepstone/Upload'
import AddAttachment from '../AddAttachment'
import { Datepicker } from '../../../base/DatePickerBase'
import TextareaAutosize from '../../../base/TextareaAutosize'
import RadioGroup from '../../../base/RadioGroup'
import Autocomplete from '../../../base/Autocomplete'
import AutocompleteApi from '../../../base/Autocomplete/variants/AutocompleteApi'
import MultiSelect from '../../../base/MultiSelect'
import Checkbox from '../../../base/Checkbox'
import UploadSection from '../../../base/UploadSection/UploadSection'

const InputType = (props) => {
    const {
        disabled = false,
        displayValue,
        displayEmpty,
        disableCloseOnSelect,
        field,
        name,
        options,
        optionLabel,
        optionValue,
        placeholder,
        type = 'text',
        groups,
        limitTags,
        defaultValues,
        row,
        isRequired,
        multiple,
        apiCall,
        onResults,
        freeSolo,
        onValueChange,
        groupBy,
        tooltip,
        inderteminate,
        label,
        value,
        loading,
        loadingText,
        renderOption,
        renderValue,
        paperComponent,
        isOptionEqualToValue,
        icon,
        getOptionLabel,
        minRows,
        maxRows,
        onChange,
        filterOptions,
        size,
        step,
        min,
        max,
        noResultsText,
    } = props

    const {
        formState: { errors },
    } = useFormContext()

    const helperText = errors[name] ? errors[name].message : ''
    const isError = !!errors[name]

    const commonProps = {
        error: isError,
        helperText: helperText,
        ...field,
        disabled: disabled,
    }

    if (onChange) {
        commonProps.onChange = onChange
    }

    switch (type) {
        case 'addAttachments':
            return (
                <AddAttachment {...commonProps} displayValue={displayValue} />
            )
        case 'date':
            return <Datepicker {...commonProps} />
        case 'checkbox':
            return (
                <Checkbox
                    isThemed
                    {...commonProps}
                    checked={commonProps?.value || false}
                    tooltip={tooltip}
                    inderteminate={inderteminate}
                    disabled={disabled}
                    label={label}
                />
            )
        case 'autocomplete':
            return (
                <Autocomplete
                    {...commonProps}
                    disableCloseOnSelect={disableCloseOnSelect}
                    field={field}
                    options={options}
                    optionLabel={optionLabel}
                    optionValue={optionValue}
                    isThemed={true}
                    multiple={multiple}
                    freeSolo={freeSolo}
                    limitTags={limitTags}
                    loading={loading}
                    loadingText={loadingText}
                    renderOption={renderOption}
                    paperComponent={paperComponent}
                    icon={icon}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={getOptionLabel}
                    filterOptions={filterOptions}
                    size={size}
                />
            )
        case 'autocompleteApi':
            return (
                <AutocompleteApi
                    {...commonProps}
                    optionLabel={optionLabel}
                    optionValue={optionValue}
                    multiple={multiple}
                    apiCall={apiCall}
                    onResults={onResults}
                    freeSolo={freeSolo}
                    onValueChange={onValueChange}
                    groupBy={groupBy}
                    loading={loading}
                    loadingText={loadingText}
                    noResultsText={noResultsText}
                />
            )
        case 'dropdown':
            return (
                <DropdownTextField
                    {...commonProps}
                    options={options}
                    opLabel={optionLabel}
                    opValue={optionValue}
                    isRequired={isRequired}
                    loading={loading}
                    loadingText={loadingText}
                    multiple={multiple}
                    displayEmpty={displayEmpty}
                    renderValue={renderValue}
                />
            )
        case 'multiselect':
            return (
                <MultiSelect
                    {...commonProps}
                    options={options}
                    groups={groups}
                    limitTags={limitTags}
                    defaultValues={defaultValues}
                />
            )
        case 'upload':
            return <Upload {...commonProps} />
        case 'file':
            return <UploadSection {...commonProps} multiple={multiple} />
        case 'textarea':
            return (
                <TextareaAutosize
                    {...commonProps}
                    minRows={minRows}
                    maxRows={maxRows}
                />
            )
        case 'radiogroup':
            return (
                <RadioGroup
                    {...commonProps}
                    isThemed={true}
                    options={options}
                    row={row}
                />
            )
        case 'stepper':
            return (
                <InputStepperField
                    {...commonProps}
                    placeholder={placeholder}
                    step={step}
                    min={min}
                    max={max}
                />
            )
        default:
            return <InputTextField {...commonProps} placeholder={placeholder} />
    }
}

export default InputType
